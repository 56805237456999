import { Menu } from './../../_service/_interface/menu.interface';

export class SidebarOrg {
  public menu_admin: Menu[] = [
    {
      name: 'Empresa',
      router: '/admin/dashboard/company',
      icon: 'settings',
      title: 'companies',
    },

    {
      name: 'Ajustes',
      router: '/admin/dashboard/configuration',
      icon: 'settings',
      title: 'configurations',
    },

    {
      name: 'Cotización manual',
      router: '/admin/dashboard/quote',
      icon: 'settings',
      title: 'currency_quote',
    },

    {
      name: 'Pagadores',
      router: '/admin/dashboard/configuration-fee',
      icon: 'settings',
      title: 'payers',
    },

    {
      name: 'Autorizados',
      router: '/admin/dashboard/subsidiary',
      icon: 'home',
      title: 'authorized',
    },

    {
      name: 'Usuarios',
      router: '/admin/dashboard/user',
      icon: 'users',
      title: 'users',
    },

    {
      name: 'Remessas',
      router: '/admin/dashboard/invoice',
      icon: 'file-text',
      title: 'invoices',
    },

    {
      name: 'Financiero',
      icon: 'bar-chart-2',
      title: 'financial',
      itens: [
        {
          name: 'Extracto',
          router: '/admin/dashboard/finance/extract',
          title: 'financial',
        },
        {
          name: 'Crédito',
          router: '/admin/dashboard/finance/credit',
          title: 'financial',
        },
      ],
    },

    {
      name: 'Administrador de archivos',
      icon: 'file',
      title: 'orchestrator',
      itens: [
        {
          name: 'Inbound',
          router: '/admin/dashboard/orchestrator/inbound',
          title: 'orchestrator',
        },
        {
          name: 'Outbound',
          router: '/admin/dashboard/orchestrator/outbound',
          title: 'orchestrator',
        },
        {
          name: 'Transmisión',
          router: '/admin/dashboard/orchestrator/transmission',
          title: 'orchestrator',
        },
      ],
    },

    {
      name: 'Clientes',
      router: '/admin/dashboard/client',
      icon: 'user',
      title: 'clients',
    },
  ];

  public menu_agent: Menu[] = [
    {
      name: 'Dashboard',
      router: '/admin/dashboard/dash-agent',
      icon: 'align-left',
      title: 'dashboard',
    },

    {
      name: 'Envíos',
      router: '/admin/dashboard/invoice',
      icon: 'file-text',
      title: 'invoices',
    },

    {
      name: 'Financiero',
      icon: 'bar-chart-2',
      title: 'financial',
      itens: [
        {
          name: 'Extracto',
          router: '/admin/dashboard/finance/extract',
          title: 'financial_extract',
        },
        {
          name: 'Crédito',
          router: '/admin/dashboard/finance/credit',
          title: 'financial_credit',
        },
      ],
    },

    {
      name: 'Usuarios',
      router: '/admin/dashboard/user',
      icon: 'users',
      title: 'users',
    },

    {
      name: 'Clientes',
      router: '/admin/dashboard/client',
      icon: 'user',
      title: 'clients',
    },
  ];

  public menu_manager: Menu[] = [
    {
      name: 'Ajustes',
      router: '/admin/dashboard/configuration',
      icon: 'settings',
    },
    {
      name: 'Pagar el costo',
      router: '/admin/dashboard/configuration-fee',
      icon: 'settings',
    },
    {
      name: 'Financiero',
      icon: 'bar-chart-2',
      itens: [
        {
          name: 'Extracto',
          router: '/admin/dashboard/finance/extract',
        },
        {
          name: 'Crédito',
          router: '/admin/dashboard/finance/credit',
        },
      ],
    },

    {
      name: 'Empresa',
      router: '/admin/dashboard/company',
      icon: 'home',
    },

    {
      name: 'Autorizados',
      router: '/admin/dashboard/subsidiary',
      icon: 'home',
    },

    {
      name: 'Usuarios',
      router: '/admin/dashboard/user',
      icon: 'users',
    },

    {
      name: 'Envíos',
      router: '/admin/dashboard/invoice',
      icon: 'file-text',
    },
  ];

  public menu_employee: Menu[] = [
    {
      name: 'Dashboard',
      router: '/admin/dashboard/dash-agent',
      icon: 'align-left',
      title: 'dashboard',
    },

    {
      name: 'Envíos',
      router: '/admin/dashboard/invoice',
      icon: 'file-text',
      title: 'invoices',
    },

    {
      name: 'Clientes',
      router: '/admin/dashboard/client',
      icon: 'user',
      title: 'clients',
    },
  ];

  public menu_agent_callcenter: Menu[] = [
    {
      name: 'Dashboard',
      router: '/admin/dashboard/dash-agent',
      icon: 'align-left',
      title: 'dashboard',
    },

    {
      name: 'Envíos',
      router: '/admin/dashboard/invoice',
      icon: 'file-text',
      title: 'invoices',
    },

    {
      name: 'Financiero',
      icon: 'bar-chart-2',
      title: 'financial',
      itens: [
        {
          name: 'Extracto',
          router: '/admin/dashboard/finance/extract',
          title: 'financial_extract',
        },
      ],
    },

    {
      name: 'Clientes',
      router: '/admin/dashboard/client',
      icon: 'user',
      title: 'clients',
    },
  ];
}

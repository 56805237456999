<div class="auth-container">
  <div class="row auth-main">
    <div class="col-sm-6 px-0 d-none d-sm-block">
      <div class="left-img" style="background-image: url(assets/images/404.jpg);">
      </div>
    </div>
    <div class="col-sm-6 auth-form-section">
      <div class="form-section">
        <div class="auth-wrapper">
          <form>
            <span class="error-header p-b-45">
              404
            </span>
            <span class="error-subheader p-b-5">
              ¡OPS! NO PODEMOS ENCONTRAR ESTA PÁGINA
            </span>
            <span class="error-subheader2 p-b-5">
              ¡La página que estás buscando no está disponible!
            </span>
            <div class="container-auth-form-btn mt-5">
              <button mat-flat-button color="primary" routerLink="/authentication/signin" class="auth-form-btn" type="submit">
                Para volver
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

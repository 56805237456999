import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AlertService } from "src/app/_service/alert/alert.service";
import { AppService } from "src/app/_service/app.service";
import { LoadingService } from "src/app/_service/loading/loading.service";
import { AuthService } from "src/app/_service/user/auth.service";
import Swal from "sweetalert2";
import { Menu } from './../../_service/_interface/menu.interface';
import { SidebarOrg } from './sidebar-org';
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;

  appInfo: any = JSON.parse(localStorage.getItem("app_info"));

  //---------------------------------------------------------------
  //VARIAVEIS UTEIS
  user: any;
  public items_menu: Menu[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private loading: LoadingService,
    private alert: AlertService,
    private router: Router,
    private appService: AppService,
    private auth: AuthService
  ) {
    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ["admin", "employee", "client"];
        const currenturl = event.url.split("?")[0];
        const firstString = currenturl.split("/").slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = event.url.split("/")[2];
          this.level2Menu = event.url.split("/")[3];
        } else {
          this.level1Menu = event.url.split("/")[1];
          this.level2Menu = event.url.split("/")[2];
        }

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }


  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest("li");
      const activeClass = parentElement.classList.contains("active");

      if (activeClass) {
        this.renderer.removeClass(parentElement, "active");
      } else {
        this.renderer.addClass(parentElement, "active");
      }
    }
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    this.getUser();
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  async getUser() {
    let resp = await this.auth.userAsync();
    if (resp?.success) {
      this.authService.userData.subscribe(res => {
        if (res) {
          this.user = res;
          this.returnMenu(this.user.role);
        }
      });
    }
  }

  public returnProperty(value: any) {
    return this.user.permissions.menu[value];
  }

  public returnMenu(role: string) {
    switch (role) {
      case "ADMIN":
        this.items_menu = new SidebarOrg().menu_admin
        break;

      case "AGENT":
        this.items_menu = new SidebarOrg().menu_agent;
        if (this.user?.permissions?.is_callcenter) {
          this.items_menu.splice(3, 1);
          this.items_menu[2].itens.splice(1, 1);
        }
        break;

      case "MANAGER":
        this.items_menu = new SidebarOrg().menu_manager
        break;

      case "EMPLOYEE":
        this.items_menu = new SidebarOrg().menu_employee
        break;
    }
    this.setMenu();
  }

  public setMenu() {
    this.items_menu = this.items_menu.filter(item => this.returnProperty(item.title) == true);
    this.items_menu.map((element: any) => {
      if (element.itens) {
        element.itens = element.itens.filter(item => this.returnProperty(item.title) == true);
      }
    });
  }

  //----------------------------------------------------------------------
  //LOGOUT
  logout() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Você deseja sair ?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.loading.show('Desconectando...');
        setTimeout(() => {
          this.loading.hide();
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("currentUser");
          this.appService.getAppInfo().subscribe((response) => {
            localStorage.setItem("app_info", JSON.stringify(response))
            this.user = null;
            this.router.navigate(['/'])
          });
        }, 2000);
      }
    })
  }//fim logout
}
